import { useState, useEffect } from "react";
import PopinForm from "../Popin/PopinForm";
import { ContainerDataGrid } from "./styles/DataTable.styled";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Filters from "./Filters";
import { Button, IconButton, Tooltip } from "@mui/material";
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon, AddBox as AddBoxIcon, ListAlt as ListAltIcon, Edit as EditIcon, SearchOffOutlined as SearchOffOutlinedIcon, SavedSearchOutlined as SavedSearchOutlinedIcon, PushPinOutlined as PushPinOutlinedIcon } from "@mui/icons-material";
import { getHashedIds } from "../../services/previews";
import SavedSearchPopin from "../Popin/PopinSavedSearch";
import { Search as SearchIcon, Grade as GradeIcon } from "@mui/icons-material";
import { addSearch } from "../../services/searches.js";
import { useAuth } from "../../hooks/use-auth";
import { StyledDataGrid } from "./styles/DataTable.styled";
import { useGridApiRef } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";
import { roles } from "../../utils/roles.js";

const AddButton = ({ openPopinAdd }) => {
  return (
    <Button startIcon={<AddBoxIcon />} onClick={openPopinAdd}>
      Add
    </Button>
  );
};

const SearchButton = ({ getData, handleRowsState }) => {
  const fetchData = () => {
    handleRowsState("page", 0);
    getData();
  };

  return (
    <Tooltip title="Search">
      <IconButton onClick={fetchData} aria-label="Search" size="medium">
        <SearchIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

const ResetSearchButton = ({ resetSearch }) => {
  return (
    <Tooltip title="Reset filters">
      <IconButton onClick={resetSearch} aria-label="Reset filters" size="medium">
        <SearchOffOutlinedIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

const FavoriteSearchButton = ({ handleSavedSearchesPopin }) => {
  const { getUserType } = useAuth();
  const userType = getUserType();

  if (userType === "EXTERN") {
    return null;
  }

  return (
    <Tooltip title="Saved searches">
      <IconButton onClick={handleSavedSearchesPopin.bind(null, true)} aria-label="Saved searches" size="medium">
        <SavedSearchOutlinedIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

const RegisterSearchButton = ({ saveSearch }) => {
  const { getUserType } = useAuth();
  const userType = getUserType();
  if (userType === "EXTERN") {
    return null;
  }

  return (
    <Tooltip title="Save current search">
      <IconButton onClick={saveSearch} aria-label="Save current search" size="medium">
        <PushPinOutlinedIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

const ExportLinkButton = ({ selectionModel, redirectToPreviewsPage }) => {
  const { getUserType } = useAuth();
  const userType = getUserType();
  if (userType === "EXTERN") {
    return null;
  }

  return (
    !!selectionModel.length && (
      <>
        <Button startIcon={<ListAltIcon />} onClick={redirectToPreviewsPage}>
          Export links
        </Button>
        <Button startIcon={<ListAltIcon />} onClick={() => redirectToPreviewsPage(true)}>
          Export anonymous links
        </Button>
      </>
    )
  );
};

export default function EnhancedTable({ permissions, headCells, entity: { tableId, label }, fetchData, addData: addToList, deleteData: deleteFromList, updateData: updateList, setPopinAlert }) {
  const [popin, setPopin] = useState({ isOpen: false, action: null, title: null });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [filters, setFilters] = useState({});
  const [isSavedSearch, setIsSavedSearch] = useState(false);
  const [isOpenPopinSearch, setIsOpenPopinSearch] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const { getUserType, user } = useAuth();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const hasFilter = !!headCells.find(({ isOnFilter }) => isOnFilter);
  const userType = getUserType();

  const headCellList = headCells.filter((cell) => !cell.isTest || (userType === "TEST" && cell.isTest));

  const popinFieldList = headCells;

  const handleFiltersList = (newFilters) => {
    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const handleRowsState = (id, value) => {
    setRowsState((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (triggerSearch) {
      getData();
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  const getData = async (currentFilter) => {
    try {
      setIsLoading(true);
      const fetchedData = await fetchData({ offset: rowsState.page, limit: rowsState.pageSize, filters: currentFilter || filters, sortModel });
      setData(fetchedData);
      setRowCount(+fetchedData[0]?.fullCount || 0);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      setData([]);
      setIsLoading(false);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  const addData = async (newData) => {
    try {
      const fetchedData = await addToList(newData);
      dispatch(displaySuccessFeedback({ message: fetchedData.message || "Success." }));
      await getData();
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  const deleteData = async (dataId) => {
    try {
      const fetchedData = await deleteFromList(dataId);
      dispatch(displaySuccessFeedback({ message: fetchedData.message || "Success." }));
      await getData();
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  const updateData = async (newData) => {
    try {
      const fetchedData = await updateList(newData);
      dispatch(displaySuccessFeedback({ message: fetchedData.message || "Success." }));
      await getData();
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  const handleSavedSearchesPopin = (isOpen) => {
    setIsOpenPopinSearch(isOpen);
  };

  const saveSearch = async () => {
    try {
      const addedSearch = await addSearch(filters);
      setIsSavedSearch(true);
      dispatch(displaySuccessFeedback({ message: addedSearch.message || "Success." }));
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  const resetSearch = () => {
    setFilters({});
  };

  useEffect(() => {
    getData();
  }, [rowsState.page, rowsState.pageSize]);

  useEffect(() => {
    const filtersContainer = document.querySelector(".container-filters");

    if (!filtersContainer) return;

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        const activeElement = document.activeElement;

        const isTextInput = activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA";

        const isDropdownOpen = !!filtersContainer.querySelector("[aria-expanded='true']");

        if (isDropdownOpen) {
          return;
        }

        if (isTextInput) {
          setTriggerSearch(true);
        }
      }
    };

    filtersContainer.addEventListener("keydown", handleKeyDown);

    return () => {
      filtersContainer.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const openPopinAdd = () => {
    const itemData = popinFieldList.reduce((acc, val) => {
      return { ...acc, [val.field]: val.isSelectMultiple ? [] : null };
    }, {});
    setPopin({ isOpen: true, action: async (newData) => await addData(newData), title: `Add ${label}`, itemData });
  };

  const openPopinUpdate = (row) => {
    setPopin({ isOpen: true, action: async (newData) => await updateData(newData), title: `Update ${label}`, itemData: row });
  };

  const getCustomActionCells = (row) => {
    const isAdminAccount = user.role === roles.SUPERADMIN;
    const hasPermission = permissions.update.find((role) => role === user.role || role === roles.ALL);
    const isAgencyOwner = row.agencyId ? row.agencyId === user.agencyId : true;
    const isExternRole = user.role === roles.EXTERN;
    let canUpdate = (hasPermission && isAgencyOwner) || isAdminAccount;

    if (isExternRole) {
      canUpdate = row.creatorAccountType === roles.EXTERN;
    }

    const availableEditItem = <GridActionsCellItem icon={<EditIcon />} onClick={openPopinUpdate.bind(this, row)} label={`Edit ${label}`} />;
    const unavailableEditItem = <GridActionsCellItem disabled icon={<EditIcon />} label={`Edition unavailable`} />;
    const editItem = canUpdate ? availableEditItem : <></>;
    const topProjectIcon = <GridActionsCellItem icon={<GradeIcon />} label={`Top project`} />;
    const customActionCells = headCellList.filter((cell) => cell.cellType === "actions");

    const visibilityItem = customActionCells.reduce((acc, cell) => {
      if (cell.actionType === "link") {
        if (row.previewLink) {
          if (row.canShare) {
            return [
              ...acc,
              <a key={cell.field} style={{ color: "#000" }} aria-label="Sharable preview" href={row.previewLink} rel="noreferrer" target="_blank">
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
              </a>,
            ];
          } else {
            return [
              ...acc,
              <a key={cell.field} style={{ color: "#000" }} href={row.previewLink} rel="noreferrer" target="_blank">
                <IconButton disabled aria-label="Unsharable preview">
                  <VisibilityOffIcon />
                </IconButton>
              </a>,
            ];
          }
        } else {
          return acc;
        }
      } else {
        return acc;
      }
    }, []);

    return [...(row.isTopProject ? [topProjectIcon] : []), editItem, ...visibilityItem];
  };

  const actionCells = {
    field: "actions",
    type: "actions",
    sortable: false,
    editable: false,
    isOnTable: true,
    width: 120,
    hideable: false,
    getActions: (params) => getCustomActionCells(params.row),
  };

  const dataToDisplay = [...headCellList.filter((cell) => (cell.isSuperAdminEditOnly ? user.role === roles.SUPERADMIN || cell.isOnTable : cell.isOnTable)), actionCells];

  const options = {
    checkboxSelection: false,
    isRowSelectable: () => false,
    loading: true,
    classes: ["virtualScroller"],
  };

  const onCellClick = (cell) => {
    if (cell.field === "edit") {
      openPopinUpdate(cell.row);
    }
  };

  const redirectToPreviewsPage = async (isAnonymous) => {
    const hashedId = await getHashedIds({ previewIdList: selectionModel.join(","), agencyId: user.agencyId, isAnonymous });
    if (!hashedId) return;
    const url = `/previews?id=${hashedId}`;
    const newPage = window.open(url, "_blank");
    newPage.focus();
    handleSelectionModel([]);
  };

  const handleSelectionModel = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const handleSortModelChange = async (sortModelData) => {
    if (sortModelData[0]?.field) {
      setSortModel(sortModelData[0]);
    } else {
      setSortModel(null);
    }
    getData();
  };

  const CustomToolbar = () => {
    const { dateTo, dateFrom, ...filtersProperties } = filters;
    const hasActiveFilter = !!Object.keys(filters).length;
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 10px" }}>
        <div>
          <AddButton openPopinAdd={openPopinAdd} />
          {hasFilter && <ExportLinkButton selectionModel={selectionModel} redirectToPreviewsPage={redirectToPreviewsPage} />}
        </div>
        <div>
          {hasFilter && hasActiveFilter && tableId === "projects" && <RegisterSearchButton saveSearch={saveSearch} />}
          {hasFilter && hasActiveFilter && <ResetSearchButton resetSearch={resetSearch} />}
          {hasFilter && <SearchButton getData={getData} handleRowsState={handleRowsState} />}
          {hasFilter && label === "project" && <FavoriteSearchButton handleSavedSearchesPopin={handleSavedSearchesPopin} />}
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: "20px", width: "100%", height: "100%", display: "flex" }}>
      <ContainerDataGrid style={{ display: "flex", height: "100%", width: "100%" }}>
        {label === "project" && <SavedSearchPopin isOpen={isOpenPopinSearch} handleClose={handleSavedSearchesPopin.bind(null, false)} setFilters={setFilters} />}
        <PopinForm tableId={tableId} headCells={popinFieldList} isOpen={popin.isOpen} title={popin.title} action={popin.action} handleClose={() => setPopin({ ...popin, isOpen: false })} itemData={popin.itemData} deleteData={deleteData} />
        <div style={{ flexGrow: 1, width: "100%", display: "flex", flexDirection: "column" }}>
          {hasFilter && <Filters headCells={headCellList} getData={getData} setFilters={setFilters} filters={filters} handleFiltersList={handleFiltersList} />}
          <CustomToolbar />
          <StyledDataGrid
            apiRef={apiRef}
            onPageSizeChange={(newPageSize) => handleRowsState("pageSize", newPageSize)}
            onPageChange={(newPage) => handleRowsState("page", newPage)}
            onCellClick={onCellClick}
            onSelectionModelChange={handleSelectionModel}
            selectionModel={selectionModel}
            columns={dataToDisplay}
            rows={data}
            page={rowsState.page}
            pageSize={rowsState.pageSize}
            componentsProps={{
              columnMenu: { color: "primary" },
            }}
            options={options}
            loading={isLoading}
            rowCount={rowCount}
            paginationMode="server"
            sortingMode="server"
            rowsPerPageOptions={[25, 50, 100]}
            disableColumnFilter
            disableVirtualization
            getRowId={(row) => row.id}
            checkboxSelection={label === "project"}
            isRowSelectable={({ row }) => row.canShare && !!row.previewLink}
            onSortModelChange={handleSortModelChange}
            keepNonExistentRowsSelected
            sx={{
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
            }}
          />
        </div>
      </ContainerDataGrid>
    </div>
  );
}
