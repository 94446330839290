import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

export default function BasicDatePicker({ id, onChange, dateValue, size = "small", variant = "standard", views = ["year", "month", "day"], label = "Date" }) {
  const handleChange = (newValue) => {
    if (!newValue) return;
    const formattedDate = new Date(newValue.$d);

    if (dayjs(formattedDate).isValid()) {
      const formattedValue = formattedDate.toISOString().split("T")[0] + "T00:00:00.000Z";
      onChange(id, formattedValue);
    }
  };

  const validDate = dateValue && dayjs(dateValue).isValid() ? dayjs(dateValue) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker inputFormat="DD/MM/YYYY" label={label} views={views} value={validDate} onChange={handleChange} renderInput={(params) => <TextField {...params} size={size} variant={variant} />} />
    </LocalizationProvider>
  );
}
